<template>
    <div class="pa-4">
        <!-- FILTRO DE DATAS -->
        <v-form ref="formPesquisa">
            <h3>Pesquisa de Exames</h3>
            <v-divider class="my-2"></v-divider>
            <v-row class="px-3" dense>
                <!-- <v-col>
                    <v-text-field v-model="data_inicial" label="Data inicial" :disabled="loading_agendamentos" type="date">
                    </v-text-field>
                </v-col>
                <v-col>
                    <v-text-field v-model="data_final" label="Data final" :disabled="loading_agendamentos" type="date">
                    </v-text-field>
                </v-col> -->
                <v-col class="pl-10" v-show="false">
                    <v-switch v-model="tipo_agendamento_bool" @click="getMedicos('')"
                        :label="`${tipo_agendamento_bool ? 'Consultas' : 'Exames'}`"></v-switch>
                </v-col>
            </v-row>
            <v-row dense>
                <!--                
                <v-col sm="6" md="7" dense>
                    -- <v-autocomplete required :loading="loading_planos"
                        :no-data-text="loading_planos ? 'Carregando recurso...' : 'Sem recurso'"
                        placeholder="Selecione um recurso" :rules="[ruleNotEmpty]" dense hide-details item-value="ds_recurso_central"
                        item-text="ds_recurso_central" v-model="filtro" outlined :items="Exames" label="Recurso"
                        :disabled="!cd_convenio" clearable>
                    </v-autocomplete>
                </v-col> -->
                <v-col sm="6" md="6" dense>
                    <v-date-picker v-model="dates" :items="items" range locale="pt" landscape></v-date-picker>
                </v-col>
                <v-col sm="6" md="5" dense>
                </v-col>
                <v-autocomplete :rules="[ruleNotEmpty]" outlined dense hide-details :loading="loading_empresas"
                    :disabled="loading_empresas || loading_agendamentos" v-model="pesquisa_empresa"
                    prepend-inner-icon="mdi-hospital-box" @change="filtraMedicos()" placeholder="Selecione a empresa..."
                    label="Empresa" :items="empresas" item-value="cd_multi_empresa" item-text="ds_multi_empresa"
                    :no-data-text="loading_empresas ? 'Carregando Empresa...' : 'Sem empresa'" class="mt-5"
                    no-results-text="Nenhum registro encontrado" clearable>
                </v-autocomplete>
                <v-autocomplete required :rules="[ruleNotEmpty]" dense item-value="cd_convenio" item-text="nm_convenio"
                    v-model="cd_convenio" :disabled="!pesquisa_empresa || loading_agendamentos" outlined :items="convenios"
                    hide-details label="Convênio" @change="getSubPlanos('get_subplanos', cd_convenio)" class="mt-5"
                    clearable placeholder="Selecione um convênio">
                </v-autocomplete>
                <v-autocomplete required :loading="loading_planos"
                    :no-data-text="loading_planos ? 'Carregando planos...' : 'Sem planos'" placeholder="Selecione um plano"
                    :rules="[ruleNotEmpty]" dense hide-details item-value="cd_con_pla" item-text="ds_con_pla"
                    v-model="cd_plano" outlined :items="planos" label="Plano" class="mt-5" :disabled="!cd_convenio"
                    clearable>
                </v-autocomplete>
                <v-autocomplete dense item-text="ds_item_agendamento" outlined v-model="item_agendamento" hide-details
                    :loading="loading_medicos" @change="filtroUnidade(item_agendamento)" :items="item_agendamentos"
                    clearable item-value="cd_item_agendamento" label="Item de agendamento"
                    placeholder="Selecione um item de agendamento" :rules="nameRules" />
                <v-autocomplete :items="Olhos" v-model="lateralidade" clearable dense placeholder="Selecione a lateralidade"
                    :rules="nameRules" outlined />
                <v-autocomplete :loading="loading_medicos"
                    :disabled="!pesquisa_empresa || loading_agendamentos || loading_medicos" :items="filtrado"
                    item-value="cd_unidade" v-model="unidade" item-text="nm_unidade"
                    :no-data-text="loading_medicos ? 'Carregando Unidades...' : 'Sem Unidades'"
                    no-results-text="Nenhum registro encontrado" required="required" clearable :rules="nameRules" outlined
                    dense placeholder="Selecione a unidade" />
            </v-row>
        </v-form>
        <v-form>
            <v-row>
                <v-col>
                    <h3 class="mt-8">Informações para Exames</h3>
                    <v-col class="d-flex justify-end " ><v-icon @click="getExames()" class="bg-gray">mdi-refresh</v-icon></v-col>
                    <v-date-picker v-model="DataLivre" @input="showHorarioLivre" :allowed-dates="allowedDates" class="mt-3" landscape
                        :min= d  :max= end  />
                    <v-dialog v-model="show">
                        <v-card >
                           <v-col class="d-flex justify-end"> <v-icon class="end mg-2 mt" @click="show = false" >mdi-close</v-icon></v-col>
                            <v-data-table :headers="headers" :items="filtroExame" :items-per-page="5"
                                class="elevation-1 ma-" :disabled="!pesquisa_empresa || !cd_item_agendamento"
                                :loading="loading_exames" item-key="cd_it_agenda_central" :lazy="true"
                                @update:page="getExames">
                                <template v-slot:[`item.checked`]="{ item }">
                                    <v-btn @click="selecionarExame(item)" :value="item" :color="'primary'"
                                        v-if="!item.status_envio">
                                        Selecionar
                                    </v-btn>
                                    <v-btn v-else disabled>Horario selecionado</v-btn>
                                </template>
                                <template v-slot:[`item.hr_agenda`]="{ item }">
                                    {{ item.hr_agenda | formatDataTime }}
                                </template>
                            </v-data-table>

                        </v-card>
                    </v-dialog>
                </v-col>
            </v-row>
            <v-row dense>
            </v-row>
        </v-form>
        <v-dialog v-model="dialog" width="500" :persistent="true">
            <v-card>
                <v-card-title>
                    <v-row align='center'>
                        <p class="mt-5 mx-auto mb-5">Exame </p>
                    </v-row>
                </v-card-title>
                <v-card-text>
                    <v-list-item v-for="item in lista" v-bind:key="item.id" id="resumo">
                        <v-list-item-icon>
                            <v-icon dark>
                                mdi-checkbox-marked-circle
                            </v-icon>
                        </v-list-item-icon>
                        <p> Paciente:{{ item.nm_paciente }}<br />
                            Convenio:{{ cd_convenio }}&ensp; Plano:{{ cd_plano }}<br />
                            Data e hora:{{ item.hr_agenda || formatDataTime }}<br />
                            Lateralidade:{{ lateralidade }}<br />
                            Item_agenda_central:{{ item.cd_it_agenda_central }}<br />
                            Item_agendamento:{{ item_agendamento }}<br />
                            Unidade:{{ unidade }}</p>
                    </v-list-item>
                    <v-row class="mb-2">
                        <v-divider />
                    </v-row>
                    <v-row align="center">
                        <v-btn dark width=230 class="mx-auto my-3 mb-3" color="secondary" @click="desabilitar()">
                            <v-icon left>mdi-close</v-icon>
                            FECHAR
                        </v-btn>
                        <v-btn dark width=230 class="mx-auto my-3 mb-3" color="secondary" @click="copiar(lista)">
                            {{ copiado ? 'Texto copiado!' : 'Copiar' }}
                            <v-icon class="ml-3" left>mdi-content-copy</v-icon>
                        </v-btn>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-dialog>
        <template>
            <v-card justify-center max-width="1000" class="mt-2">
                <v-list>
                    <v-subheader>Selecionados</v-subheader>
                    <v-list-item-group>
                        <v-list-item v-for="item in lista" v-bind:key="item.id">
                            <v-list-item-icon>
                                <v-progress-circular :indeterminate="item.status_envio" color="blue"></v-progress-circular>
                            </v-list-item-icon>
                            <v-list-item-content>
                                Item_agendamento:{{ item_agendamento }}<br/>
                                Convenio:{{ cd_convenio }}<br/>
                                Data e Hora:{{ item.hr_agenda | formatDataTime  }}
                            </v-list-item-content>
                            <v-icon @click="cancelarExame(item)">mdi-close</v-icon>
                        </v-list-item>
                    </v-list-item-group>
                </v-list>
                <v-row class="ma-4 justify-end">
                    <v-btn class="ml-2 " :disabled="enviar_disabled" color="blue" @click="submitModal(lista)"
                        dark>Enviar</v-btn>
                    <v-btn class="ml-2 " @click="btn_disabled_envia(lista)" color="red" dark>Resetar
                        <v-icon>mdi-clean</v-icon>
                    </v-btn>
                </v-row>
            </v-card>
        </template>
    </div>
</template>
<script>
import AppEtlService from '@/service/AppEtlService'
import AppConfigService from '@/service/AppConfigService'
import pt from 'date-fns/locale/pt';

const api = new AppEtlService();
var d = new Date();
var end = new Date()
end.setMonth(end.getMonth() + 1)
d.setDate(d.getDate() - 30)
export default {

    name: 'Agendar',
    props: {
        agenda: {
            type: Object
        },
        exameAtual: {
            type: Object
        },
    },
    data() {
        return {
            inicio: '',
            fim: '',
            ExamesMarcados: [],
            lista_controle: [{ cd_it_agenda_central: null }],
            nameRules: [v => !!v || 'Campo Obrigatorio'],
            search: '',
            it: 0,
            show: false,
            dates: [],
            enviar_disabled: false,
            dialog: false,
            Olhos: ['27-Olho Direito', '28-Olho Esquerdo ', '29-Ambos os olhos'],
            unidade: '',
            data_inicial: null,
            data_final: null,
            pesquisa_medico: null,
            pesquisa_unidade: null,
            pesquisa_empresa: [{ cd_multi_empresa: null }],
            tipo_agendamento_bool: true,
            agendamentos: [],
            agendasMarcadas: [],
            tmp_agendamentos: [],
            monitor_agendamentos: [],
            disabledDates: '',
            medicos: [],
            filtro: null,
            DataLivre:'',
            filtroU: 0,
            empresas: [{ cd_multi_empresa: null }],
            selected: [],
            select: false,
            planos: [],
            tipos_marcacao: [],
            search_agenda: '',
            loading_agendamentos: false,
            loading_medicos: false,
            loading_planos: false,
            loading_item_agendamentos: false,
            loading_item_reservado: false,
            loading_empresas: false,
            loading_exames: false,
            show_table: false,
            agenda_selecionada: {},
            unidade_atendim: [],
            unidades: [],
            convenios: [],
            item_agendamentos: [],
            cd_item_agendamento: '',
            cd_convenio: '',
            cd_plano: '',
            cd_tip_mar: '',
            observacao: '',
            lateralidade: '',
            dispara: false,
            Exames: [],
            lista: [],
            ExameUnd: [],
            filtrado: [],
            mensagem: false,
            time: null,
            item_agendamento: null,
            listaFinal: [],
            copiado: false,
            items: [d, end],
            headers: [
                { text: 'Hora agenda', value: 'hr_agenda' },
                {
                    text: 'Selecionar',
                    value: 'checked'
                }
            ],
        }
    },
    methods: {
        ruleNotEmpty: (value) => !!value,
        showHorarioLivre() {
            this.show = true
        },
        async getConvenios(url, obj) {
            this.loading = true
            return await api.get_query(url, obj)
                .then(response => {
                    this.convenios = response.data

                }).catch(error => {
                    console.log(error.response)
                }).finally(() => {
                    this.loading = false
                })
        },
        async getUnidade() {
            this.loading = true
            const response = await api
                .get_query('get_unidade_atendime', [this.pesquisa_empresa])
                .then(res => res
                )
                .catch(err => {
                    console.log(err)
                    return err.response
                })
            this.unidade_atendim = response.data
            this.loading = false
        },
        filtraMedicos() {
            if (this.pesquisa_empresa === null) {
                return
            }
            if (this.pesquisa_empresa == 2) {
                this.getConvenios('get_convenios_vm', [])
            } else {
                this.getConvenios('get_convenios', ['', '%'])
            }
            this.getUnidade()
            this.getMedicos('get_medicos', [this.data_inicial, this.data_final, this.data_inicial,
            this.data_final, '%', '%', '%', '%', this.data_inicial, this.data_final, this.pesquisa_empresa]);
            this.getExames()
        },
        getItemAgendamento() {
            this.loading_item_agendamentos = true
            return api
                .get_etl('etl/query', `cod_query=get_item_exame_agen`)
                .then((response) => { this.item_agendamentos = response.data; })
                .catch(error => { console.log(error) })
                .finally(() => { this.loading_item_agendamentos = false })
        },
        async getAgendaLivreMedico(inicial, final, empresa, medico, monitor = false, unidade, itemAgendamento) {
            if (!this.tipo_agendamento_bool) {
                medico = 1
            }
            if (monitor) {
                this.loading_agendamentos = true
            }
            let url = 'get_agenda_central'

            let params = [medico || '%', inicial, final, empresa, "A", unidade || '%', itemAgendamento]
            if (!this.tipo_agendamento_bool) {
                url = 'get_agenda_exames'
                params = [empresa, inicial, final]
            }

            return api
                .get_query(url, params)
                .then((response) => {
                    this.agendamentos = response.data;
                    this.unidades = [... new Set(this.agendamentos.map((agenda) => agenda.ds_unidade_atendimento))]
                    this.tmp_agendamentos = response.data; //verificar a necessidade de manter esta variavel 
                })
                .catch(error => { console.log(error.response) })
                .finally(() => { this.loading_agendamentos = false })
        },
        async getMedicos(url, params) {

            this.getItemAgendamento()
            this.loading_medicos = true
            return api
                .get_query(url, params)
                .then((response) => { this.medicos = response.data })
                .catch(error => { console.log(error) })
                .finally(() => { this.loading_medicos = false })
        },
        async getSubPlanos(url, obj) {
            this.loading = true
            this.loading_planos = true
            api.get_query(url, [obj])
                .then(res => this.planos = res.data)
                .catch(error => error.response)
            this.loading = false
            this.loading_planos = false
        },
        async getTiposMarcacao(url) {
            this.loading = true
            return await api.get_query(url)
                .then(response => {
                    this.tipos_marcacao = response.data
                }).catch(error => {
                    console.log(error.response)
                }).finally(() => {
                    this.loading = false
                })
        },
        atualizar() {
            this.getExames()
            this.getAgendamentosPaciente()
        },
        async getEmpresas(url, empresas) {
            this.loading_empresas = true
            return await api.get_query(url, empresas)
                .then(response => {
                    this.empresas = response.data
                }).catch(error => {
                    console.log(error.response)
                }).finally(() => {
                    this.loading_empresas = false
                })
        },
        selecionarExame(item) {
            try {
                item.checked = !item.checked
                if (item.checked === true) {
                    this.lista.push(item)
                    this.listaFinal.forEach((e) => {
                        this.lista.forEach(hora => {
                            if (e.hr_agenda === hora.hr_agenda) {
                                e.status_envio = true
                            }
                        })
                    })
                    api.get_query('get_agenda_check', [item.cd_it_agenda_central])
                        .then((res) => {
                            let v = res.data[0]
                            if (v.cd_usuario === null && v.nm_paciente === null) {
                                let conf = [this.agenda.nm_paciente, this.usuario, item.cd_it_agenda_central]
                                api.put_etl('etl/query-put/?cod_query=put_agenda_reserva', conf)
                                    .then(() => {
                                    }).catch(() => { alert('Houve um erro ao reservar o exame.') })
                            } else {
                                alert(`O exame está sendo agendado pelo usuario:${this.usuario}`)
                                this.loading_exames = true
                                this.getExames()
                                this.cancelarExame(item)
                            }
                        })
                } else {
                    item.checked = false
                    item.status_envio = false
                    alert('Deseja cancelar o exame?')
                    this.cancelarExame(item)
                }
            } catch (error) {
                console.log(error)
            }
        },
        getAgendamentosPaciente() {
            api.get_query('get_exame_marcados')
                .then((response) => {
                    this.ExamesMarcados = response.data
                })
        },
        cancelarExame(item) {
            const index = this.lista.findIndex(objeto => objeto.cd_it_agenda_central === item.cd_it_agenda_central);
            if (index !== -1) {
                this.lista.splice(index, 1);
            }
            item.checked = false
            item.lateralidade = ''
            item.unidade = ''
            item.status_envio = false
            item.cd_it_agendamento_q = ''
            this.listaFinal.forEach((q) => {
                if (q.hr_agenda === item.hr_agenda) {
                    q.status_envio = false
                }
            })
            let conf = [null, null, item.cd_it_agenda_central]
            api
                .put('etl/query-put/?cod_query=put_agenda_reserva', conf)
                .then(() => {
                    console.log('Cancelado')
                })
                .catch((error) => error.response)
        },
        getExames() {
            this.loading_exames = true
            let params = [`${this.pesquisa_empresa}`, `${this.dates[0]}`, `${this.dates[1]}`]
            api.get_query(`get_agenda_exames`, params).then((response) => {
                this.Exames = response.data
                let dataNasc = this.agenda.dt_nascimento.split('T')
                let dataformatada = `${dataNasc[0]} ${dataNasc[1]}`
                this.Exames.forEach(e => {
                    e.checked = false
                    e.cd_it_agendamento_q = this.item_agendamento
                    e.status_envio = false
                    e.cd_paciente = this.agenda.cd_paciente
                    e.ddd_nr_telefone = this.agenda.nr_ddd_fone
                    e.ddd_nr_celular = this.agenda.nr_ddd_celular
                    e.nr_fone = this.agenda.nr_fone
                    e.nr_celular = this.agenda.nr_celular
                    e.usuario = this.usuario
                    e.nm_paciente = this.agenda.nm_paciente
                    e.tp_sexo = this.agenda.tp_sexo
                    e.dt_nascimento = dataformatada
                });
                let fa = this.Exames.map(e => e.hr_agenda.split('T')[0])
                this.disabledDates = fa.filter((item, index) => fa.indexOf(item) === index);
                 const remover = this.ExamesMarcados.map(itemSecundaria => itemSecundaria.hr_agenda);
                const resultado = this.Exames.filter(itemPrimaria => !remover.includes(itemPrimaria.hr_agenda));
                const lar = resultado.filter((value, index, self) => {
                    return self.findIndex(item => item.hr_agenda === value.hr_agenda) === index;
                });
                this.listaFinal = lar
                this.loading_exames = false
            }).catch(error => console.log(error)).finally(() => { this.loading_exames = false })
        },
        submitModal() {
            try {
                if (this.lista[this.it] !== undefined) {
                    if (this.it < this.lista.length) {
                        if (this.lista[this.it].unidade === null && this.lista[this.it].lateralidade === null) {
                            alert("Campos Vazios")
                        } else {

                            this.lista[this.it].status_envio = true
                            let a = Object.values(this.lista[this.it])
                            console.log('Lista em formato de array', a)
                            let valor = this.lateralidade.replace(/[^0-9]/g, '')
                            console.log(this.lista)
                            const e = parseInt(valor)
                            let ExamesMontado = [
                                a[6],
                                a[12],
                                a[14],
                                this.item_agendamento,
                                a[11],
                                this.cd_convenio,
                                this.planos[0].cd_con_pla,
                                a[13],
                                a[7],
                                a[8],
                                a[9],
                                a[10],
                                e,
                                a[2]

                            ]

                            this.enviar_disabled = true
                            //this.envia(this.it)
                            console.log("Objeto para enviar", ExamesMontado)
                            api
                                .put('etl/query-put/?cod_query=put_agenda_exames', ExamesMontado)
                                .then(() => {
                                    // console.log("Exames antes ",this.Exames)
                                    this.Dados = ExamesMontado

                                    this.getExames()
                                    this.atualizar()
                                    this.clearItemAgendado()

                                }).catch((error) => error.response).finally(() => {
                                    this.lista[this.it].status_envio = false
                                    this.it++
                                    this.submitModal()
                                    this.dialog = true


                                })

                        }
                    }

                }
            } catch (error) {
                console.log(error)
            }
        },
        clearItemAgendado() {
            let conf = [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, this.exameAtual.cd_it_agenda_central]
            api
                .put('etl/query-put/?cod_query=put_agenda_clear', conf)
                .then(() => {
                    console.log('Desmarcado')
                    this.getAgendamentosPaciente()

                })
                .catch((error) => error.response)
        },
        desabilitar() {

            this.dialog = false
            this.lista = []
            this.enviar_disabled = false

        },
        btn_disabled_envia(item) {
            this.enviar_disabled = false
            this.it = 0
            this.lista = []
            item.map((e) => {
                e.checked = false
                e.lateralidade = ''
                e.unidade = ''
                e.cd_it_agendamento_q = ''
                e.status_envio = false
                api
                    .put('etl/query-put/?cod_query=put_agenda_reserva', [null, null, e.cd_it_agenda_central])
                    .then((res) => {
                        console.log(e.checked)

                        console.log(res, "cancelado", e.checked)
                    })
                    .catch((error) => error.response)
            })
            this.Exames.map(v => v.status_envio = false)
        },
        getExamesUnd() {
            try {
                api.get_query('hro_exames_und').then((response) => {
                    this.ExameUnd = response.data
                })
            } catch (error) {
                console.log(error)
            }
        },
        filtroUnidade(item) {

            let a = this.ExameUnd.filter(e => {

                return e.cd_item_agendamento === item
            })

            return this.filtrado = a
        },
        checkRemacarcao() {
            if (this.ExameAtual !== null) {
                api.put_etl('etl/query-put/?cod_query=del_agenda_paciente', JSON.stringify([this.ExameAtual.cd_it_agenda_central]))
            }
        },
        copiar(list) {
            if (navigator.clipboard) {
                let newList = '';
                for (let item of list) {
                    let dataIso = item.hr_agenda;
                    let data = new Date(dataIso);
                    let dia = data.getDate().toString().padStart(2, '0');
                    let mes = (data.getMonth() + 1).toString().padStart(2, '0');
                    let ano = data.getFullYear().toString().padStart(4, '0');
                    let hora = data.getHours().toString().padStart(2, '0');
                    let minuto = data.getMinutes().toString().padStart(2, '0');
                    let dataHoraFormatada = `${dia}/${mes}/${ano} ${hora}:${minuto}`;
                    newList += `
                            Paciente:${item.nm_paciente}\n
                            Convenio:${this.cd_convenio}\n 
                            Plano:${this.cd_plano}\n
                            Recurso:${item.ds_recurso_central}\n
                            Data e hora:${dataHoraFormatada}\n-
                            Lateralidade:${item.lateralidade}\n
                            Item_agenda_central:${item.cd_it_agenda_central}\n
                            Item_agendamento:${item.cd_it_agendamento_q}\n
                            Unidade:${item.unidade}`;
                }
                navigator.clipboard.writeText(newList).then(() => { console.log('Copiado') })
                this.copiado = true


            }

        },
        allowedDates(val){
            return this.disabledDates.includes(val)
        }
    },
    computed: {
        usuario: () => {
            let storage = JSON.parse(localStorage.getItem('cfgu'))
            return storage.usuario_mv.toUpperCase()
        },
        empresasmv: () => {
            let multi_empresas = JSON.parse(localStorage.getItem('cfgu')).multi_empresas
            return [0, 0, 0].map((_, idx) => multi_empresas[idx] ?? "")
        },
        tiposmarcacao() {
            return this.tipo_agendamento_bool ? this.tipos_marcacao : [{ "cd_tip_mar": 16, "ds_tip_mar": "EXAMES AMBULATORIAL" }]
        },
        tipo_agendamento() {

            return this.tipo_agendamento_bool ? 'A' : 'I'
        },
        meses() {
            return AppConfigService.meses()
        },
        computedIsSelectedAgenda() {
            if (this.agenda_selecionada && this.agenda_selecionada.agenda && this.agenda_selecionada.agenda.cd_it_agenda_central) {
                return this.agenda_selecionada.agenda.cd_it_agenda_central
            } else {
                return 0
            }
        },
        filtroExame() {
            let a = this.listaFinal.filter(e => e.hr_agenda.slice(0,10) === this.DataLivre);
            return a
        },
    },
    watch: {
        //Setar informações nos campos na hora de remarcar
        exameAtual: {
            deep: true,
            handler(value) {
                if (value !== null) {
                    this.getEmpresas('get_empresas', this.empresasmv)
                    this.pesquisa_empresa = Number(value.cd_multi_empresa)
                    this.getConvenios('get_convenios', [value.cd_convenio, '%'])
                    this.cd_convenio = value.cd_convenio
                    this.getSubPlanos('get_subplanos', this.cd_convenio)
                    this.cd_plano = value.cd_con_pla
                    this.getExames()
                }
            },

        },
    },
    mounted() {
        this.getExamesUnd()
        let dt = new Date()
        let dttmp = new Date()
        let dtf = new Date(dttmp.setMonth(dttmp.getMonth() + 1))
        this.data_inicial = dt.toISOString().split('T')[0]
        this.data_final = dtf.toISOString().split('T')[0]
        if (!this.usuario && this.usuario.length < 0) {
            alert("Usuário MV não informado, entre em contato com seu supervisor para atualizar o seu cadastro")
        } else {
            this.getTiposMarcacao('get_tipo_marcacao')
            this.getItemAgendamento()
            this.getEmpresas('get_empresas', this.empresasmv)
        }
        this.getAgendamentosPaciente()
        this.$vuetify.lang.current = 'pt';
        this.$vuetify.lang.locales.pt = pt;

    },
    beforeUnmount() {
        this.$emit('clearAgenda')
    },
}
</script>

